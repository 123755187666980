<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t((type === 'person') ? 'basicData.personTitle' : 'basicData.companyTitle')}}</h1>
        <person-basic-data-form
            v-if="type === 'person'"
            @submit="onSubmit"
            @cancel="onCancel"
        />
        <company-basic-data-form
            v-else
            @submit="onSubmit"
            @cancel="onCancel"
        />
    </div>
</template>

<script>
import CompanyBasicDataForm from './Components/CompanyBasicDataForm.vue'
import PersonBasicDataForm from './Components/PersonBasicDataForm.vue'

export default {
    name: 'BasicDataPage',
    components: {
        PersonBasicDataForm,
        CompanyBasicDataForm
    },
    props: {
        type: {
            type: String,
            required: true,
            validator (value) {
                return ['person', 'company'].indexOf(value) > -1
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onSubmit () {
            if (this.type === 'person') {
                this.$router.push({ name: 'headquartersAddress' })
            } else {
                this.$router.push({ name: 'contactPerson' })
            }
        },
        onCancel () {
            this.$router.push({ name: 'typeSelection' })
        }
    }
}
</script>
