<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <div class="row">
            <div class="col-12 col-md-2">
                <form-input-select
                    v-model="salutation"
                    :id="'basicData.salutation'"
                    :label="$t('basicData.salutation')"
                    :validation="$v.salutation"
                    :options="salutationOptions"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-2">
                <form-input
                    v-model="prenominal"
                    :label="$t('basicData.prenominal')"
                    :id="'basicData.prenominal'"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="firstName"
                    :id="'basicData.firstName'"
                    :validation="$v.firstName"
                    :label="$t('basicData.firstName')"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="lastName"
                    :id="'basicData.lastName'"
                    :validation="$v.lastName"
                    :label="$t('basicData.lastName')"
                />
            </div>
            <div class="col-12 col-md-2">
                <form-input
                    v-model="postnominal"
                    :id="'basicData.postnominal'"
                    :label="$t('basicData.postnominal')"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="legalId"
                    :id="'basicData.legalId'"
                    :label="$t('basicData.legalId')"
                    :validation="$v.legalId"
                    :hint="$t((type === 'person') ? 'basicData.legalIdPersonHint' : 'basicData.legalIdCompanyHint')"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input-date-picker
                    v-model="dateOfBirth"
                    :id="'basicData.dateOfBirth'"
                    :label="$t('basicData.dateOfBirth')"
                    :validation="$v.dateOfBirthAsDate"
                    :max-date="maxDate"
                    :button-only="true"
                    :date-placeholder="$t('datePlaceholder')"
                />
            </div>
            <div class="col-12 col-md-4">
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="email"
                    :id="'basicData.email'"
                    :label="$t('basicData.email')"
                    :validation="$v.email"
                />
                <form-input
                    v-model="phoneNumber"
                    :id="'basicData.phoneNumber'"
                    :label="$t('basicData.phoneNumber')"
                    :validation="$v.phoneNumber"
                    :formatter="formatIncompletePhoneNumber"
                    :hint="$t('basicData.phoneNumberHint')"
                    :validation-messages="{ isValidNumber: $t('basicData.invalidPhoneNumberFormat') }"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="skype"
                    :id="'basicData.skype'"
                    :label="$t('basicData.skype')"
                />
                <form-input
                    v-model="website"
                    :id="'basicData.website'"
                    :label="$t('basicData.website')"
                />
            </div>
        </div>

        <p class="form-text">{{$t('requiredFieldsNotice')}}</p>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('basicData.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('basicData.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { BasicData } from '../../../DTO/BasicData.dto'
import { required, email, maxValue } from 'vuelidate/lib/validators'
import { isValidNumber, formatIncompletePhoneNumber } from 'libphonenumber-js'
const minDate = new Date()
minDate.setHours(23, 59, 0, 0)
minDate.setFullYear(minDate.getFullYear() - 15)

export default {
    name: 'PersonBasicDataForm',
    data () {
        return {
            ...((new BasicData(this.$store.state.userData.basicData)).toObject())
        }
    },
    computed: {
        maxDate () {
            const date = new Date()
            date.setHours(23, 0, 0, 0)
            date.setFullYear(date.getFullYear() - 15)
            return date
        },
        salutationOptions () {
            return JSON.parse(JSON.stringify(this.$store.state.enums.salutationOptions))
        },
        dateOfBirthAsDate () {
            return (this.dateOfBirth) ? new Date(this.dateOfBirth) : null
        }
    },
    methods: {
        formatIncompletePhoneNumber (value) {
            if (value) {
                return formatIncompletePhoneNumber(value)
            }
            return value
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('basicData.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new BasicData(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            /*
            const data = new BasicData({ type: this.$store.state.userData.basicData.type })
            this.$store.commit('mergeData', data)
            */
            this.$emit('cancel')
        }
    },
    validations: {
        firstName: {
            required
        },
        lastName: {
            required
        },
        salutation: {
            required
        },
        phoneNumber: {
            required,
            isValidNumber (value) {
                if (value) {
                    return isValidNumber(value)
                }
                return null
            }
        },
        email: {
            required,
            email
        },
        dateOfBirthAsDate: {
            required,
            maxValue: maxValue(minDate)
        },
        legalId: {
            required
        }
    }
}
</script>
