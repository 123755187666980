<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <div class="row">
            <div class="col-12 col-md-8">
                <form-input
                    v-model="companyName"
                    :id="'basicData.companyName'"
                    :label="$t('basicData.companyName')"
                    :validation="$v.companyName"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="legalId"
                    :id="'basicData.legalId'"
                    :label="$t('basicData.legalId')"
                    :validation="$v.legalId"
                    :hint="$t((type === 'person') ? 'basicData.legalIdPersonHint' : 'basicData.legalIdCompanyHint')"
                />
            </div>
            <div class="col-12 col-md-4">
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <form-input
                    v-model="email"
                    :id="'basicData.email'"
                    :label="$t('basicData.email')"
                    :validation="$v.email"
                />
                <form-input
                    v-model="phoneNumber"
                    :id="'basicData.phoneNumber'"
                    :label="$t('basicData.phoneNumber')"
                    :validation="$v.phoneNumber"
                    :formatter="formatIncompletePhoneNumber"
                    :hint="$t('basicData.phoneNumberHint')"
                    :validation-messages="{ isValidNumber: $t('basicData.invalidPhoneNumberFormat') }"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input
                    v-model="skype"
                    :id="'basicData.skype'"
                    :label="$t('basicData.skype')"
                />
                <form-input
                    v-model="website"
                    :id="'basicData.website'"
                    :label="$t('basicData.website')"
                />
            </div>
        </div>

        <p class="form-text">{{$t('requiredFieldsNotice')}}</p>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('basicData.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('basicData.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { BasicData } from '../../../DTO/BasicData.dto'
import { required, email } from 'vuelidate/lib/validators'
import { isValidNumber, formatIncompletePhoneNumber } from 'libphonenumber-js'

export default {
    name: 'CompanyBasicDataForm',
    data () {
        return {
            ...((new BasicData(this.$store.state.userData.basicData)).toObject())
        }
    },
    computed: {
        maxDate () {
            const date = new Date()
            date.setHours(12, 0, 0, 0)
            date.setDate(date.getDate() + 1)
            return date
        }
    },
    methods: {
        formatIncompletePhoneNumber (value) {
            if (value) {
                return formatIncompletePhoneNumber(value)
            }
            return value
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('basicData.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new BasicData(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        }
    },
    validations: {
        companyName: {
            required
        },
        phoneNumber: {
            required,
            isValidNumber (value) {
                if (value) {
                    return isValidNumber(value)
                }
                return null
            }
        },
        email: {
            required,
            email
        },
        legalId: {
            required
        }
    }
}
</script>
